import React from "react";
import Page from "./../components/Page";
import SEO from "./../components/SEO";
import WithSideNav from "../components/WithSideNav";
import Section from "../components/Section";
import DocumentTimeline from "../components/DocumentTimeline";

const timelineData = [
  {
    date: "May 2023 - May 2028",
    description:
      "The main goals of our project are to enhance the OHIF platform by incorporating advanced imaging features and user-focused workflows for the cancer research community. We also aim to simplify the software installation process for both on-premises and cloud-hosted instances, making it more user-friendly. Additionally, we plan to expand our community support resources and centralize design documentation in a quality management system, providing researchers with a solid foundation for commercialization efforts.",
    subtitle:
      "National Cancer Institute, Informatics Technology for Cancer Research (ITCR)",
    title:
      "Sustained Support for Informatics Technologies for Cancer Research and Management (U24)",
    type: "grant",
    key: "grant-1"
  },
  {
    date: "September 2022 - September 2024",
    description:
      "To develop, upgrade, and migrate documentation; perform software maintenance; and provide community support for the Open Health Imaging Foundation web-based medical imaging framework.",
    subtitle: "Chan Zuckerberg Initiative",
    title: "Essential Open Source Software for Science (EOSS 5)",
    type: "grant",
    key: "grant-2"
  },
  {
    date: "January 2021 - January 2023",
    description:
      "To partner with Hack.Diversity to serve as curriculum designers and mentors, equipping their Fellows to contribute to open source scientific software, as well as mentor existing global OHIF contributors.",
    subtitle: "Chan Zuckerberg Initiative",
    title: "Diversity and Inclusion (D&I)",
    type: "grant",
    key: "grant-3"
  },
  {
    date: "December 2020 - December 2021",
    description:
      "To develop training materials, perform software maintenance, expand outreach, and provide community support for the Open Health Imaging Foundation (OHIF) web-based medical imaging framework including its underlying libraries (e.g., Cornerstone).",
    subtitle: "Chan Zuckerberg Initiative",
    title: "Essential Open Source Software for Science (EOSS 3)",
    type: "grant",
    key: "grant-4"
  },
  {
    date: "September 2015 - August 2020",
    description:
      "To create a vendor-neutral, extensible, zero-footprint HTML5 image viewer for web browser–based display and analysis of imaging studies, optimized for oncology clinical trial workflows.",
    subtitle:
      "National Cancer Institute, Informatics Technology for Cancer Research (ITCR)",
    title:
      "Advanced Development of Informatics Technologies for Cancer Research and Management (U24)",
    type: "grant",
    key: "grant-5"
  }
];

const sideNavProps = [
  {
    title: "About Us",
    link: "/about"
  },
  {
    title: "Team & Collaborators",
    link: "/team"
  },
  {
    title: "Get Support",
    link: "/get-support"
  },
  {
    title: "Funding History",
    link: "/history"
  }
];

function TeamPage() {
  return (
    <Page>
      <SEO title="History | OHIF" />
      <Section>
        <WithSideNav sideNavProps={sideNavProps} title="Funding History">
          <div className="space-y-6">
            {/* Map over the timelineData array to create a DocumentTimeline component for each object */}
            {timelineData.map((data) => (
              <DocumentTimeline
                date={data.date}
                description={data.description}
                subtitle={data.subtitle}
                title={data.title}
                type={data.type}
                key={data.key}
              />
            ))}
          </div>
        </WithSideNav>
      </Section>
    </Page>
  );
}

export default TeamPage;
